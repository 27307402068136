<template>

  <router-view :key="$route.fullPath"></router-view>

</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
  @import '@/style/utilities';
  html{
    height: 100%;
  }
  body{
    cursor: url("./assets/pizzaCurs-1.png"), auto;
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(2,0,36,0.8771709367340687) 0%, rgba(169,18,198,1) 100%);
    height: 100%;
  }
  #app {
    font-family: 'Inconsolata', monospace;
    height: 100%;
  }
  h1, h2, h3, h4, h5{
    letter-spacing: 5px;
  }
  p{
    letter-spacing: 2.5px;
  }  
  .icon{
    width: 400px;
    height: 400px;
  }
  nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  /**********
        RESPONSIVE 
  ***********/
  @media screen and (max-width: 767px) {
    p{
      letter-spacing: 1.4px;
    }
  }
</style>
