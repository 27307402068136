<template>
    <footer class="container-fluid p-5">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0 d-flex justify-content-md-center">
                    <div class="d-flex flex-column">
                        <a href="mailto: trimarcofederico98@gmail.com" target="_blank" class="cursor-pointer">trimarcofederico98@gmail.com</a>
                        <span>Federico Trimarco <i class="far fa-copyright mt-2"></i> {{currentYear}}</span>
                    </div>
                </div>
                
                
                <div class="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
                     <ul class="list-none d-flex justify-content-md-center">
                        <li v-for="(social, id) in socialList" :key="id" class="me-3">
                            <a :href="social.link" target="_blanck">
                                <i :class="social.icon" :title="social.name"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterComponent',
    data(){
        return{
            currentYear: new Date().getFullYear(),
            socialList: [
                {
                    name: 'Linkedin',
                    icon: 'fab fa-linkedin cursor-pointer',
                    link: 'https://www.linkedin.com/in/federico-trimarco-85923a228/'
                },
                {
                    name: 'GitHub',
                    icon: 'fa-brands fa-github cursor-pointer',
                    link: 'https://github.com/FedericoTrimarco',
                },
                {
                    name: 'Instagram',
                    icon: 'fab fa-instagram cursor-pointer',
                    link: 'https://www.instagram.com/fede.trimarco/',
                },
                {
                    name: 'Facebook',
                    icon: 'fab fa-facebook-square cursor-pointer',
                    link: 'https://www.facebook.com/federico.trimarco.7'
                },
                /* {
                    name: 'WhatsApp',
                    icon: 'fa-brands fa-whatsapp cursor-pointer',
                    link: 'tel:+393926043364'
                }, */
            ],
        }
    },
}
</script>

<style scoped lang="scss">
    @import '@/style/utilities';
    a{
        color: #fff;
        text-decoration: none;
        transition: .2s ease-in;
        word-wrap: anywhere;
        
        &:hover{
            color: #00ff00;
            text-shadow:
            0 0 7px #00ff00,
            0 0 10px #00ff00,
        }
    }
    footer{
        margin-top: 3rem;
        background-color: #251332;
        font-weight: bold;
        color: #fff;
        li{
            font-size: 1.7rem;
            transition: .3s ease-in-out;
            a{
                color: #fff;
                transition: .2s ease-in;
            }
            &:hover{
                a{
                    color: #00ff00;
                    text-shadow:
                    0 0 7px #00ff00,
                    0 0 10px #00ff00,
                }
                transform: scale(1.3);
            }
        }
    }
</style>