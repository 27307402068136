<template>
  <section id="sectionThree" class="container mt-5 mt-lg-4">
        <h2 class="primary-color text-center text-md-start fw-bold">LEARNING</h2>
        <div class="row">
            <div class="col-12 col-md-6 info p-5 d-flex flex-column">
                <div class="img-container align-self-center" data-aos="fade-right">
                    <img src="../assets/unisa.png" alt="UNISA">
                </div>
                <div class="info-learning" data-aos="fade-up">
                    <h4 class="secondary-color text-center mt-3 fw-bold">University of Salerno</h4>
                    <p class="text-white fs-5 mt-4 text-center text-md-start">Is an Italian State university founded in 1968 with headquarters in Fisciano - Salerno. It is one of the best Italian universities in computer science.</p>
                </div>
            </div>
            <div class="col-12 col-md-6 info p-5 d-flex flex-column">
                <div class="img-container align-self-center" data-aos="fade-left">
                    <img src="../assets/boolean.png" alt="BOOLEAN-CAREERS">
                </div>
                <div class="info-learning" data-aos="fade-up">
                    <h4 class="secondary-color text-center mt-3 fw-bold">Boolean Careers</h4>
                    <p class="text-white fs-5 mt-4 text-center text-md-start">Full-time intensive course on Front-End technologies e
                    Back-End with daily exercises with increasing difficulty in which
                    replicate real projects from the world of work.</p>
                </div>
            </div>
        </div>
  </section>
</template>

<script>
export default {
    name: 'SectionThree'
}
</script>

<style scoped lang="scss">
    @import '@/style/utilities';
    #sectionThree{
        .info{
            position: relative;
            &:nth-child(1):after{
                content: '';
                height: 50%;
                width: 5px;

                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
                background-color: #00ff00;
                border-radius: 5px;
            }
        }
        .img-container{
            width: 120px;
            height: 120px;
            border-radius: 100px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        /**********
            RESPONSIVE 
        ***********/
        @media screen and (max-width: 767px) {
            .info{
                position: relative;
                &:nth-child(1):after{
                    display: none;
                }
            }
        }
    }
</style>