<template>
  <div class="icon-prog h-100 w-100" :class="{ animate: animation }">
    <div class="container-fluid h-100">
        <div class="row  h-100">
            <div class="col-4 ">
                <div class="row h-100">
                    <div class="col-12 h-50">
                        <div class="row h-100 justify-content-center">
                            <div class="col-2 ret-1 h-100">
                                <div class="row ret-son">
                                    <div class="col-12"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 h-50">
                        <div class="row h-100 justify-content-center">
                            <div class="col-2 ret-2 h-100">
                                <div class="row ret-son">
                                    <div class="col-12"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row h-100">
                    <div class="col-12 h-100">
                        <div class="row h-100 justify-content-center">
                            <div class="col-2 ret-3 h-100">
                                <div class="row ret-son">
                                    <div class="col-12"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row h-100">
                    <div class="col-12 h-50">
                        <div class="row h-100 justify-content-center">
                            <div class="col-2 ret-4 h-100">
                                <div class="row ret-son">
                                    <div class="col-12"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12  h-50">
                        <div class="row h-100 justify-content-center">
                            <div class="col-2 ret-5 h-100">
                                <div class="row ret-son">
                                    <div class="col-12"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'IconaProg',
    data(){
        return{
            animation: false,
        }
    },
    methods:{
        flagAnimation(){
            setInterval(() => {
                this.animation = !this.animation;
            }, 1500);
        }
    },
    beforeMount(){
        this.flagAnimation()
    },
}
</script>

<style scoped lang="scss">
    .icon-prog{
        &.animate{
            .ret-son{
                height: 100%;
            }
            .ret-1 > .ret-son, .ret-2 > .ret-son{
                background-color: #008d44;
            }
            .ret-3 > .ret-son{
                background-color: #fff;
            }
            .ret-4 > .ret-son, .ret-5 > .ret-son{
                background-color: #ec4242;
            }
        }
    }
    .ret-1{
        transform: rotate(30deg);
    }
    .ret-2{
        transform: rotate(-30deg);
    }
    .ret-3{
        transform: rotate(15deg);
    }
    .ret-4{
        transform: rotate(-210deg);
    }
    .ret-5{
        transform: rotate(210deg);
    }

    .ret-1, .ret-2, .ret-3, .ret-4, .ret-5{
        border-radius: 50px;
        overflow: hidden;
        .ret-son{
            height: 0;
            transition: all 1s linear;
        }
    }
</style>