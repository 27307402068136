import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import NotFound from '../views/NotFound.vue';
import VideoSectionPage from '../views/VideoSectionPage.vue';
import GptSectionVue from '@/views/GptSection.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/Giulia',
    name: 'Giulia',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/video-page',
    name: 'video-page',
    component: VideoSectionPage
  },
  {
    path: '/gpt-section',
    name: 'gpt-section',
    component: GptSectionVue
  },
  {
    path: '/:notFound',
    component: NotFound
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
