<template>
    <header class="container-fluid position-relative">
        <div class="row">
            <div class="col d-flex justify-content-center">
                <img src="../assets/bg-sito.jpg" alt="Federico-Trimarco-2" class="d-none">
                <div class="img-container position-absolute top-100 start-50 translate-middle" data-aos="fade-in" @click="confirmOpenModalEg()" v-if="easterEggBind.counterEasterEgg<15">
                    <img src="../assets/prifilePic.jpeg" alt="Federico-Trimarco">
                </div>
                <div class="img-container position-absolute top-100 start-50 translate-middle" tabindex="0" data-bs-toggle="modal" data-bs-target="#modalEasterEgg" v-else>
                    <img src="../assets/prifilePic.jpeg" alt="Federico-Trimarco">
                </div>
            </div>
        </div>
    </header>

    <div ref="modalEasterEgg" class="modal fade" id="modalEasterEgg" tabindex="-1" aria-labelledby="modalEasterEgg" aria-hidden="true" v-if="easterEggBind.counterEasterEgg==15">
        <!-- Modal Easter Egg Bind-->
        <div class="modal-dialog modal-lg">
            <div class="modal-content bg-dark text-white">
                <div class="card-header d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                        <h5>HEY, YOU FOUND AN EASTER EGG!</h5>
                    </div>
                    <i data-bs-dismiss="modal" aria-label="Close" class="fa-solid fa-xmark text-white fs-4 cursor-pointer"></i>
                </div>
                <div class="card-body row">
                    <div class=" col-12 col-lg-4">
                        <label class="label text-white fw-bold mb-3">Password</label>
                        <input type="password" class="form-control py-2" v-model="easterEggBind.passwordEasterEgg" @keyup="setDataEasterEgg()" @keydown.space.prevent>
                    </div>
                    <div class=" col-12 col-lg-8 d-flex flex-column justify-content-end">

                        <button v-if="easterEggBind.statusPasswordEasterEgg != 1"
                            class="btn-neon text-center w-100 rounded py-2"
                            :class="{'notOk': easterEggBind.statusPasswordEasterEgg == 0, 'maybe': easterEggBind.statusPasswordEasterEgg == 2}"
                        >
                            <i class="fas fa-lock"></i>
                        </button>

                        <button v-else-if="easterEggBind.statusPasswordEasterEgg == 1 && !isVideoEg" data-bs-dismiss="modal" aria-label="Close" data-bs-toggle="modal" data-bs-target="#easterEggDataModal" class="btn-neon text-center w-100 rounded py-2">
                            <i class="fas fa-lock-open"></i>
                        </button>

                        <button v-else-if="easterEggBind.statusPasswordEasterEgg == 1 && isVideoEg" @click="goToVideoPage()" data-bs-dismiss="modal" aria-label="Close" class="btn-neon text-center w-100 rounded py-2">
                            <i class="fas fa-lock-open"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="easterEggDataModal" tabindex="-1" aria-labelledby="easterEggDataModalLabel" aria-hidden="true" v-if="easterEggBind.dataEasterEgg">
        <!-- Modal DATA EASTER EGG -->
        <div class="modal-dialog modal-lg">
            <div class="modal-content bg-dark text-white">
                <div class="card-header d-flex justify-content-between alingn-items-center">
                    <div class="d-flex align-items-center">
                        <h5>{{easterEggBind.dataEasterEgg.title}}</h5>
                        <i class="fa-regular fa-heart mb-2 ms-2 heart"></i>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="card-body d-flex flex-column img-modale">
                    <img :src="easterEggBind.dataEasterEgg.img" :alt="`${easterEggBind.dataEasterEgg.title}-img`">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderComponent',
    data(){
        return{
            isVideoEg: false,
            easterEggBind: {
                counterEasterEgg: 0,
                passwordEasterEgg: '',
                statusPasswordEasterEgg: 0,
                dataEasterEgg: null,
            },
        }
    },
    methods: {
        confirmOpenModalEg(){
            if(this.easterEggBind.counterEasterEgg<15){
                this.easterEggBind.counterEasterEgg++;
            }
        },

        setDataEasterEgg(){
            let pass = this.easterEggBind.passwordEasterEgg.toUpperCase();
            this.isVideoEg = false;
            this.updateString(this.easterEggBind.passwordEasterEgg)
            
            if(pass!='' && pass!='CONFEDERATI' && pass!='LUDICOLO' && pass!='TRIMARCOZINNO' && pass!='3MARKVIDEO'){
                this.easterEggBind.statusPasswordEasterEgg = 2;
            } else if(pass!='' && (pass=='CONFEDERATI' || pass=='LUDICOLO' || pass=='TRIMARCOZINNO' || pass=='3MARKVIDEO')){
                this.easterEggBind.statusPasswordEasterEgg = 1;

                if(pass=='CONFEDERATI'){
                    this.easterEggBind.dataEasterEgg = {
                        title: 'CONFEDERATI',
                        img: require('../assets/confederati.jpeg')
                    }
                } else if(pass=='LUDICOLO'){
                    this.easterEggBind.dataEasterEgg = {
                        title: 'LUDICOLO',
                        img: require('../assets/ludicolo.jpeg')
                    }
                } else if(pass=='TRIMARCOZINNO'){
                    this.easterEggBind.dataEasterEgg = {
                        title: 'FAMILY',
                        img: require('../assets/family.jpeg')
                    }
                }  else if(pass=='3MARKVIDEO'){
                    this.isVideoEg = true;
                }

            } else if(pass==''){
                this.easterEggBind.statusPasswordEasterEgg = 0;
            }
        },

        updateString(input) {
            this.easterEggBind.passwordEasterEgg = input.replace(' ', '');
        },

        goToVideoPage() {
            this.$router.push({name: 'video-page'});
        },

    }
}
</script>

<style scoped lang="scss">
    @import '@/style/utilities';
    header{
        height: 150px;
        .img-container{
            width: 13rem;
            height: 13rem;
            border-radius: 150px;
            overflow: hidden;
            -webkit-box-shadow: 0px 10px 13px -7px #000000, 21px 23px 11px -5px rgba(0,0,0,0.34); 
            box-shadow: 0px 10px 13px -7px #000000, 21px 23px 11px -5px rgba(0,0,0,0.34);
            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
    .img-modale{
        img{
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    .heart{
        color: #e96228;
        transition: all .2s ease-in;
        &:hover{
            text-shadow: 0 0 7px currentColor;
        }
    }
    /**********
        RESPONSIVE 
    ***********/
    @media screen and (max-width: 400px) {
        header{
            .img-container{
                width: 10rem;
                height: 10rem;
            }
        }
    }
</style>