import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// AOS LIBRARY
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
AOS.init({
    offset: 100,
    duration: 1000
});
createApp(App).use(router).mount('#app')
