<template>
  <Header />
  <main>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
  </main>
  <FooterComponent />
</template>

<script>
import Header from '@/components/HeaderComponent.vue';
import SectionOne from '@/components/SectionOne.vue';
import SectionTwo from '@/components/SectionTwo.vue';
import SectionThree from '@/components/SectionThree.vue';
import FooterComponent from '@/components/FooterComponente.vue';
export default {
  name: 'HomeView',
  components: {
    Header,
    SectionOne,
    SectionTwo,
    SectionThree,
    FooterComponent 
  }
}
</script>
