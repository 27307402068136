<template>
    <section id="not-found-page">
        <div class="gif-container">
            <img src="../assets/gengar-sad.gif" />
            <h1>
                404 <br />
                NOT FOUND
            </h1>
        </div>
    </section>
</template>

<script>
export default {
    name: "NotFound",
};
</script>

<style scoped lang="scss">
    @import "@/style/utilities";

    #not-found-page {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .gif-container {
            text-align: center;
            font-weight: bold;
            h1{
                color: #ada2f7;
                text-shadow:
                0 0 7px #ada2f7,
                0 0 10px #ada2f7,

            }

            img {
                width: 100%;
            }
        }
    }
</style>
