<template>
    <section id="sectionOne" class="container margin-top-8rem">
        <div class="row">
            <div class="col-lg-6 col-md-6 d-flex flex-column" data-aos="fade-right">
                <EchartsTitle :title="'FEDERICO TRIMARCO'"/>

                <span class="secondary-color fs-2 fs-lg-4 align-self-center align-self-md-start align-self-lg-start">Web Developer</span>
            </div>
            <div class="col-lg-6 col-md-6 d-flex justify-content-center">
                <ul class="list-none d-flex w-50 justify-content-between align-items-start">
                    <li v-for="(social, id) in socialList" :key="id">
                        <a :href="social.link" target="_blanck">
                            <i :class="social.icon" :title="social.name"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row flex-column-reverse flex-lg-row">
            <div class="col-lg-6 col-md-12 mt-4" data-aos="fade-right">
                <h2 class="text-center text-md-start mb-3 primary-color d-lg-none fw-bold">ABOUT ME</h2>
                <p class="text-white fs-5">Hi! My name is <strong class="third-color">Federico Trimarco</strong> and I was
                    born on 5 July 1998 in Naples - Italy.</p>

                <p class="text-white my-3 fs-5">I am a <strong class="third-color">web developer</strong> focused on the
                    <strong class="third-color">front-end</strong> side. I have always been attracted to web technologies
                    and their use, which gave vent to my creativity and inventiveness.</p>

                <p class="text-white fs-5">Currently I work for <a href="https://www.technologyadvising.it/" target="_blank"
                        class="link-style-none cursor-pointer"><strong class="third-color">Technology Advising
                            S.R.L.</strong></a>, based in Naples, and I am interested in new trends and technologies in the
                    web world.</p>
            </div>
            <div class="col-lg-6 col-md-12 p-4 d-flex justify-content-center align-self-start" data-aos="fade-up">
                <div class="GRAZIE-PAOLO-DUZIONI screen-container d-flex flex-column">
                    <div class="screen-container-header px-1">
                        <div>
                            <span class="circle"></span>
                            <span class="circle mx-1"></span>
                            <span class="circle"></span>
                        </div>
                    </div>
                    <div class="screen-container-main flex-grow-1 p-5">
                        <IconaProg />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import IconaProg from '@/components/IconaProg.vue';
import EchartsTitle from '@/components/EchartsTitle.vue';

export default {
    name: 'SectionOne',
    components: {
        IconaProg,
        EchartsTitle
    },
    data() {
        return {
            socialList: [
                {
                    name: 'Linkedin',
                    icon: 'fab fa-linkedin cursor-pointer',
                    link: 'https://www.linkedin.com/in/federico-trimarco-85923a228/'
                },
                {
                    name: 'GitHub',
                    icon: 'fa-brands fa-github cursor-pointer',
                    link: 'https://github.com/FedericoTrimarco',
                },
                {
                    name: 'Instagram',
                    icon: 'fab fa-instagram cursor-pointer',
                    link: 'https://www.instagram.com/fede.trimarco/',
                },
                {
                    name: 'Facebook',
                    icon: 'fab fa-facebook-square cursor-pointer',
                    link: 'https://www.facebook.com/federico.trimarco.7'
                },
            ],
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/style/utilities';

#sectionOne {
    li {
        font-size: 2rem;
        transition: .3s ease-in-out;

        a {
            color: #fff;
            transition: .2s ease-in;
        }

        &:hover {
            a {
                color: #00ff00;
                text-shadow:
                    0 0 7px #00ff00,
                    0 0 10px #00ff00,
            }

            transform: scale(1.3);
        }
    }

    .screen-container {
        width: 400px;
        height: 300px;
        border-radius: 7px;
        overflow: hidden;

        .screen-container-header {
            background-color: rgb(62, 64, 74);

            .circle {
                display: inline-block;
                height: 10px;
                width: 10px;
                border-radius: 15px;

                // background-color: #fff;
                &:nth-child(1) {
                    background: rgb(255, 121, 198);
                }

                &:nth-child(2) {
                    background: rgb(255, 184, 108);
                }

                &:nth-child(3) {
                    background: rgb(80, 250, 123);
                }
            }
        }

        .screen-container-main {
            background-color: rgb(34, 34, 34);
        }
    }

    /**********
            RESPONSIVE 
        ***********/
    @media screen and (min-width: 768px) and (max-width: 991px) {
        .screen-container {
            width: 100%;
            height: 400px;
        }
    }

    @media screen and (max-width: 991px) {
        .screen-container {
            width: 100%;
        }
    }
}</style>