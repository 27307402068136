<template>
    <section id="chat-bot-page">
        <div class="container h-100">
            <div class="row justify-content-center h-100">
                <div data-aos="fade-down" class="img-gpt-logo-container cursor-pointer" @click="goToHomePage()">
                    <img src="../../src/assets/logo.webp">
                </div>
                <div class="p-2 col-12" id="chat-container">
                    <div class="message" v-for="(m, id) in chatArray" :key="id" :class="{ 'mine': m.typeMessage == 0, 'bot': (m.typeMessage == 1 || m.typeMessage == 2), 'imgBot': m.typeMessage == 2 }">
                        <pre v-html="m?.messageResponse"></pre>

                        <i v-if="m.typeMessage == 0" title="REPEAT QUESTION" class="icon-repeat fa-solid fa-repeat cursor-pointer" @click="repetMessage(m.messageResponse)"></i>
                    </div>
                    <div class="message bot" v-if="isLoading">
                        Caricamento
                        <div class="custom-loader"></div> 
                    </div>
                </div>
                <div class="message-container mt-2 p-0 col-12">
                    <form class="d-flex flex-wrap align-items-center" action="" @submit.prevent="sendMessage()">
                        <div class="col-12 col-lg-10 pe-0 pe-lg-2">
                            <input v-model="userInput" rows="2" placeholder="Write your message..." class="form-control">
                        </div>
                        <div class="col-12 col-lg-2 d-none d-lg-block">
                            <button class="btn-neon purple p-0 w-100 py-2 m-0" type="submit">
                                <i class="fa-solid fa-paper-plane"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { OpenAI } from 'openai';
export default {

    name: 'GptSection',
    data(){
        return{
            userInput: '',
            chatResponse: '',
            isLoading: false,
            chatArray: [],
            openaiSettings: new OpenAI({
                //apiKey: 'sk-proj-PbSIRpDM1Caqv4D4ycpVT3BlbkFJfNgFTA9w6ua2PhQsMQr8',
                apiKey: 'sk-federico-trimarco-nqxONOQJmHskT4XNxieRT3BlbkFJ1mtRTCLz8FlUCQWcot6P',
                dangerouslyAllowBrowser: true
            })
        }
    },
    methods: {

        repetMessage(message){
            let ref = this;

            ref.userInput = message;

            ref.sendMessage();
        },

        sendMessage() {
            let ref = this;

            if (ref.userInput.trim() != "") {

                ref.chatArray.push(
                    {
                        typeMessage: 0, // 0 sta per indicare il tipo di messaggio, in questo caso la domanda fatta
                        messageResponse: ref.userInput
                    }
                )

                ref.scrollContainerHtml("chat-container", "bottom");
                ref.isLoading = true;
                // let response = ref.getResponseFromAi(ref.userInput);

                ref.getResponseFromAi(ref.userInput).then(
                    res => {
                        if (res != null) {

                            ref.chatArray.push(
                                {
                                    typeMessage: res.typeMessage, // 1 o 2 sta per indicare il tipo di messaggio, in questo caso la risposta data da openAI. 1-> testo, 2 -> immagine
                                    messageResponse: res.response
                                }
                            )
                            ref.isLoading = false;
                            ref.scrollContainerHtml("chat-container", "bottom");

                            if(res.typeMessage == 1){
                                ref.chatArray[ref.chatArray.length-1].messageResponse = "";

                                for (let i = 0; i < res.response.length - 1; i++) {
                                    ((i) => {
                                        setTimeout(() => {
                                            ref.chatArray[ref.chatArray.length-1].messageResponse += res.response[i];
                                            ref.scrollContainerHtml("chat-container", "bottom");
                                        }, 10 * i);
                                    })(i);
                                }

                            }
                        }
                    }
                );

                ref.userInput = "";

            }
        },

        scrollContainerHtml(idContainer, topOrBottom) {

            if (document.getElementById(idContainer) != null) {
                let containerRef2 = document.getElementById(idContainer);

                setTimeout(() => {
                    if (containerRef2 != null) {
                        if (topOrBottom === "bottom") {
                            containerRef2.scrollTop += containerRef2.scrollHeight;
                        } else if (topOrBottom === "top") {
                            containerRef2.scrollTop -= containerRef2.scrollHeight;
                        } else {
                            throw new Error("use 'top' or 'bottom' for topOrBottom parameter.");
                        }
                    }
                }, 800);
            }
        },

        goToHomePage(){
            this.$router.push({name: 'home'});
        },

        escapeHtml(unsafe) {
            return unsafe.replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&#039;');
        },

        convertTextToHtml(inputText) {
            const escapedText = this.escapeHtml(inputText);
            const lines = escapedText.trim().split('\n');
            const htmlLines = [];
            let codeBlock = [];
            let insideCodeBlock = false;

            for (let line of lines) {
                line = line.trim();
                if (!line) {
                    continue;  // Salta le linee vuote
                }

                if (line.includes('```')) {
                    insideCodeBlock = !insideCodeBlock;
                    if (!insideCodeBlock) {
                        const codeBlockStr = codeBlock.join('<br>\n');
                        htmlLines.push(`<div class="bg-dark-orange">\n${codeBlockStr}\n</div>`);
                        codeBlock = [];
                    }
                    continue;
                }

                if (insideCodeBlock) {
                    codeBlock.push(line);
                } else {
                    const modifiedLine = line.replace(/\b([a-z]+[A-Z][a-zA-Z]*)\b/g, '<strong class="fs-1r">$1</strong>');
                    htmlLines.push(`<p class="m-0 p-0">${modifiedLine}</p>`);
                }
            }

            return htmlLines.join('\n').trim();
        },

        async getResponseFromAi(message) {
            // Parole chiave che indicano la richiesta di un'immagine

            if(message.toLowerCase().trim() == "ciao"){

                return { typeMessage: 1, response: "Ciao, come posso esserti utile?", errorCode: 0 };

            } else {
                const imageKeywords = ["immagine", "disegna", "mostra", "visualizza", "foto", "logo", "sfondo"];
    
                // Verifica se il messaggio contiene una delle parole chiave
                const isImageRequest = imageKeywords.some(keyword => message.toLowerCase().includes(keyword));
    
                if (isImageRequest) {
                    return await this.chatAiImg(message);
                } else {
                    return await this.chatAiTxt(message);
                }
            }

        },

        async chatAiTxt(message) {

            try {
                let response = await this.openaiSettings.completions.create({
                    model: 'gpt-3.5-turbo-instruct',
                    prompt: message.trim()+"?",
                    max_tokens: 4000,
                    temperature: 0.6
                });

                console.log("response.choices[0].text >> ", response.choices[0].text);
                return { typeMessage: 1, response: this.convertTextToHtml(response.choices[0].text), errorCode: 0 } || "";

            } catch (error) {
                return { typeMessage: 1, response: "ERRORE: Richiesta non valida", errorCode: 99 };
            }
        },

        async chatAiImg(message) {
            try {
                // Parole chiave che indicano la richiesta di uno sfondo
                const imageKeywords = ["sfondo", "background"];

                // Verifica se il messaggio contiene una delle parole chiave
                const isImageRequest = imageKeywords.some(keyword => message.toLowerCase().includes(keyword)) ? "1792x1024" : "1024x1024";

                let response = await this.openaiSettings.images.generate({
                    model: "dall-e-3",
                    prompt: message.trim(),
                    size: isImageRequest,
                    quality: "standard",
                    n: 1,
                });

                return { typeMessage: 2, response: `<img src="${response.data[0].url}" class="w-100">`, errorCode: 0 } || "";

            } catch (error) {
                return { typeMessage: 1, response: "ERRORE: Richiesta non valida", errorCode: 99 };
            }
        }

    }
}
</script>

<style scoped lang="scss">
@import '@/style/utilities';

*{
    scroll-behavior: smooth;
}

#chat-bot-page{
    height: 100vh;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #363636bd;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;

    .img-gpt-logo-container{
        padding: 0;
        width: 80px;
        max-height: 80px;
        overflow: hidden;
        border-radius: 150px;
        align-self: center;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .2s ease-in-out;

        &:hover{
            outline: 3px solid #f28343;
            outline-offset: 5px;
        }

        img{
            width: 100%;
        }
    }

    #chat-container{
        height: 80%;
        display: flex;
        flex-direction: column;
        overflow: auto;
        border: 2px solid #913ef0;
        border-radius: 10px;

        .message{
            background: rgba(0, 0, 0, 0.39);
            border: 2px solid #913ef0;
            color: #fff;
            padding: 10px;
            margin: 10px 0;
            overflow: visible;
            font-weight: bold;
            word-break: break-word;
            text-wrap: wrap;
            display: flex;
            flex-direction: column;
    
            &.mine{
                align-self: end;
                border-radius: 8px 8px 0 8px;
                max-width: 80%;
            }

            &.bot{
                align-self: start;
                border-radius: 8px 8px 8px 0;
                max-width: 80%;

                &.imgBot{
                    max-width: 600px;
                    height: 600px;
                    padding: 0;
                }
            }

            .icon-repeat{
                transition: all .4s ease-in-out;
                color: #fff;
                text-align: end;
                margin-top: 8px;

                &:hover{
                    color: #00ff00;
                }
            }

            pre{
                text-wrap: wrap;
                word-break: break-word;
                margin: 0;
            }

            /* HTML: <div class="loader"></div> */
            .custom-loader {
                margin-top: 5px;
                height: 5px;
                width: 160px;
                --c:no-repeat linear-gradient(#913ef0 0 0);
                background: var(--c),var(--c),#d7b8fc;
                background-size: 60% 100%;
                animation: l16 3s infinite;
                border-radius: 10px;
            }
            @keyframes l16 {
                0%   {background-position:-150% 0,-150% 0}
                66%  {background-position: 250% 0,-150% 0}
                100% {background-position: 250% 0, 250% 0}
            }
        }
    }

    .message-container{
        height: 10%;
    }

}

@media screen and (min-width: 601px) and (max-width: 1600px) {
    #chat-bot-page{
    
        #chat-container{
            .message{
    
                &.bot{
    
                    &.imgBot{
                        max-width: 450px;
                        height: 450px;
                    }
                }
            }
        }
    
    }
}

@media screen and (max-width: 600px) {
    #chat-bot-page{
    
        #chat-container{
            .message{
    
                &.bot{
    
                    &.imgBot{
                        max-width: 300px;
                        height: 300px;
                    }
                }
            }
        }
    
    }
}
</style>