<template>

  <section id="video-page">
    <div class="container mt-5">

      <h1 class="text-center">3MARK STUDIOS</h1>

      <div class="row mt-5 justify-content-center">

        <div class="col-xl-4 col-lg-6 col-12 container-video mb-5 mt-2" v-for="(v, id) in videoList" :key="id">

          <!-- <video class="cursor-pointer" :ref="`videoElement${id}`" :currentTime="v.currentTime" muted @mouseenter="startPreview(id)" @mouseleave="stopPreview(id)" @click="toggleFullScreen(id)" data-bs-toggle="modal" data-bs-target="#detailModal" v-if="v.srcVideo">
            <source 
              :src="v.srcVideo"
            />
          </video> -->
          <img :src="v.imgVideo" :alt="v.nameVideo" class="cursor-pointer" :ref="`videoElement${id}`" @click="toggleFullScreen(id)" data-bs-toggle="modal" data-bs-target="#detailModal" v-if="v.srcVideo">

          <div v-else class="no-video-container d-flex justify-content-center align-items-center">
            <span>WAITING FOR</span>
          </div>

          <h5 class="mt-3 title-name">{{v.nameVideo}}</h5>

        </div>

      </div>
    </div>
  </section>

<div class="modal fade" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true" >
  <div class="modal-dialog modal-xl">
    <div class="modal-content bg-dark text-white">

      <div class="card-header d-flex justify-content-between alingn-items-center">
        <div class="d-flex align-items-center icon-modal">
          <h5 class="modal-title" id="detailModalLabel" v-if="videoDetail">{{videoDetail.nameVideo}}</h5>
        </div>

        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>

      <div class="card-body d-flex flex-column">
        <iframe height="500" v-if="videoDetail" :src="videoDetail.srcVideo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <!-- <video controls v-if="videoDetail">
          <source :src="videoDetail.srcVideo" />
        </video> -->
      </div>
    </div>
  </div>
</div>

</template>

<script>
export default {
    name: 'SectionTwo',
    data(){
      return{
        videoDetail: null,
        /* Per aggiungere un nuovo video, una volta messo negli assets, 
        aprire un nuovo terminale 
        -> cd src 
        -> e poi lanciare il comando: git lfs track "assets/video/nome-video.mp4" */
        videoList: [
          {
            idVideo: 1,
            srcVideo: 'https://www.youtube.com/embed/WK1VeS5oQ20?si=gQwaKEVdWe9QSpTg',
            imgVideo: require(('../assets/frank-video-img.png')),
            nameVideo: 'LAUREA FRANK',
            currentTime: 827
          },
          {
            idVideo: 2,
            srcVideo: 'https://www.youtube.com/embed/Zegb5QVfMv0?si=61xdhrS0OkDlMtKO',
            imgVideo: require(('../assets/vicenzo-video-img.png')),
            nameVideo: '27 ANNI PANDY',
            currentTime: 424
          },
        ]
      }
    },
    methods: {

      startPreview(idVideo) {
        let ref = this;
        let video = ref.$refs[`videoElement${idVideo}`][0];

        video.play();
      },

      stopPreview(idVideo) {
        let ref = this;
        const video = ref.$refs[`videoElement${idVideo}`][0];
        let currentVideo = ref.videoList.find((el, id) => id == idVideo);

        video.pause();
        video.currentTime = currentVideo.currentTime;
      },

      toggleFullScreen(idVideo) {
        let ref = this;

        ref.videoDetail = null;

        setTimeout(() => {
          ref.videoDetail = ref.videoList.find((el, id) => id == idVideo);
        }, 200);
      }
    }

}
</script>

<style scoped lang="scss">
  @import '@/style/utilities';

  #video-page{
    // border: 5px solid red;
    min-height: 100%;
    color: #fff;

    .container-video{

      img{
        width: 100%;
        height: 90%;
        object-fit: cover;
        border-radius: 15px;
        &:hover{
          outline: 3px solid #00ff00;
          outline-offset: 5px;
          box-shadow: 0 0 45px #00ff00;
        }
      }

      .no-video-container{
        width: 100%;
        height: 90%;
        background-color: #ffffff73;
        border-radius: 15px;
        &:hover{
          outline: 3px solid #ff2200;
          outline-offset: 5px;
          box-shadow: 0 0 45px #ff2200;
        }
        span{
          font-size: 4rem;
        }
      }

      .title-name{
        font-size: 15px;
      }
    }
  }

</style>