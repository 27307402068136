<template>
  <div id="dynamicTitle" style="width: 100%;height:50px;"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'EchartsTitle',
    props: {
        title: String,
    },
    data() {
        return {
            myChart: null, 
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.initChart();
            window.addEventListener('resize', this.updateChartOptions);
        });
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateChartOptions);
    }, 
    methods: {
        initChart() {
            const chartDom = document.getElementById('dynamicTitle');
            if (chartDom) {
                this.myChart = echarts.init(chartDom);
                this.updateChartOptions();
            } else {
                console.error('DOM element for ECharts not found');
            }
        },

        getChartOption() {
            const isSmallScreen = window.innerWidth <= 767;
            return {
                graphic: {
                    elements: [
                        {
                            type: 'text',
                            left: isSmallScreen ? 'center' : 'left',
                            top: 'center',
                            style: {
                                text: this.title,
                                fontSize: "2.35rem",
                                // fontWeight: 'bold',
                                fontFamily: "Inconsolata, monospace",
                                lineDash: [0, 200],
                                lineDashOffset: 0,
                                fill: 'transparent',
                                stroke: '#00ff00',
                                lineWidth: 1
                            },
                            keyframeAnimation: {
                                duration: 3000,
                                loop: false,
                                keyframes: [
                                    {
                                        percent: 0.7,
                                        style: {
                                            fill: 'transparent',
                                            lineDashOffset: 200,
                                            lineDash: [200, 0]
                                        }
                                    },
                                    {
                                        percent: 0.8,
                                        style: {
                                            fill: '#00ff00'
                                        }
                                    },
                                    {
                                        percent: 1,
                                        style: {
                                            fill: '#00ff00'
                                        }
                                    }
                                ]
                            }
                        }
                    ]
                }
            }
        },

        updateChartOptions() {
            if (this.myChart) {
                const option = this.getChartOption();
                this.myChart.setOption(option);
            }
        }
    }
}
</script>

<style>

</style>